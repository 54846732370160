import React from 'react';

import './Logo.scss';

const Logo = () => {
    return (
        <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <title>Home | Chancy Leath</title>
            <g>
                <path className="st0" d="M72.91,89.78c-2.69,2-6.1,3.41-10.25,4.2s-8.54,1.19-13.18,1.19c-5.45,0-10.64-0.9-15.56-2.7
                    c-4.92-1.8-9.26-4.55-13-8.27c-3.74-3.72-6.71-8.44-8.91-14.16c-2.2-5.72-3.3-12.51-3.3-20.37c0-8.19,1.22-15.17,3.66-20.93
                    c2.44-5.77,5.61-10.47,9.52-14.1c3.91-3.64,8.3-6.29,13.18-7.96c4.88-1.67,9.72-2.51,14.52-2.51c5.37,0,9.77,0.29,13.18,0.88
                    c3.42,0.59,6.3,1.34,8.67,2.26l-2.68,10.78c-3.99-1.92-10.05-2.88-18.19-2.88c-3.66,0-7.22,0.61-10.68,1.82
                    c-3.46,1.21-6.55,3.18-9.28,5.89c-2.73,2.72-4.9,6.27-6.53,10.65c-1.63,4.39-2.44,9.76-2.44,16.11c0,5.68,0.77,10.68,2.32,14.98
                    c1.54,4.3,3.68,7.9,6.41,10.78c2.73,2.88,5.94,5.06,9.64,6.52c3.7,1.46,7.75,2.19,12.15,2.19c3.98,0,7.45-0.38,10.37-1.13
                    c2.93-0.75,5.41-1.71,7.45-2.88L72.91,89.78z"/>
            </g>
            <g>
                <path className="st0" d="M90.79,78.13H58.5V20.02h7.05v50.8h25.24V78.13z"/>
            </g>
            <path className="st1" d="M97.82,97.93H2.18C1.53,97.93,1,97.4,1,96.75V2.44c0-0.65,0.53-1.18,1.18-1.18h95.64
                c0.65,0,1.18,0.53,1.18,1.18v94.31C99,97.4,98.47,97.93,97.82,97.93z"/>
        </svg>

    );
};

export default Logo;